// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.complete-subtasks-modal-styles__active--tCa5B {
  border-color: var(--cp-color-app-primary);
}

.complete-subtasks-modal-styles__clickable--k5k1K {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/service-workspace/components/service-details/complete-subtasks-modal.styles.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".active {\n  border-color: var(--cp-color-app-primary);\n}\n\n.clickable {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"active": `complete-subtasks-modal-styles__active--tCa5B`,
	"clickable": `complete-subtasks-modal-styles__clickable--k5k1K`
};
export default ___CSS_LOADER_EXPORT___;
