import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import styles from './reminders.styles.css';
import { CpButton, CpSelectSingle, CpDropdown } from 'canopy-styleguide!sofe';
import { reminderIntervalsMap, reminderIntervalOptions, expirationIntervalOptions } from './reminder.constants';
import { getHumanReminderForTool } from 'src/common/date.helpers';

export default class ReminderPicker extends React.Component {
  static propTypes = {
    reminders: PropTypes.shape({
      expiration_interval_days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      interval: PropTypes.string, // use this instead of reminder_interval
      reminder_interval: PropTypes.string, // deprecated
    }),
    setReminder: PropTypes.func.isRequired,
    id: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    inputClass: PropTypes.string,
    placeholder: PropTypes.string,
    longDescription: PropTypes.bool,
    returnDaysAsNumber: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const { expiration_interval_days, interval, reminder_interval } = this.props.reminders;
    let correctReminderInterval = interval || reminder_interval;
    let correctExpirationInterval = +expiration_interval_days
      ? `${+expiration_interval_days}`
      : expiration_interval_days;
    this.state = {
      displayPicker: false,
      tempExpirationIntervalDays: expirationIntervalOptions.filter(
        expInterval => expInterval.key === +correctExpirationInterval
      )[0],
      tempReminderInterval: reminderIntervalOptions.filter(
        reminderInterval => reminderInterval.key === correctReminderInterval
      )[0],
      returnDaysAsNumber: this.props.returnDaysAsNumber || typeof expiration_interval_days === 'number',
    };
    this.reminderRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { expiration_interval_days, interval, reminder_interval } = this.props.reminders;
    const reminderInterval = interval || reminder_interval;
    if (!expiration_interval_days && prevProps.reminders.expiration_interval_days) {
      this.setState({
        tempReminderInterval: '',
        tempExpirationIntervalDays: '',
      });
    } else if (expiration_interval_days && !prevProps.reminders.expiration_interval_days) {
      let correctExpirationInterval = +expiration_interval_days
        ? `${+expiration_interval_days}`
        : expiration_interval_days;
      this.setState({
        tempExpirationIntervalDays: expirationIntervalOptions.filter(
          expInterval => expInterval.key === +correctExpirationInterval
        )[0],
        tempReminderInterval: reminderIntervalOptions.filter(remInterval => remInterval.key === reminderInterval)[0],
        returnDaysAsNumber: this.props.returnDaysAsNumber || typeof expiration_interval_days === 'number',
      });
    }
  }

  render() {
    const { placeholder, inputClass, disabled, id, style, contentWidth = 'block' } = this.props;
    const { tempReminderInterval, tempExpirationIntervalDays } = this.state;

    return (
      <div style={style} ref={this.reminderRef}>
        {this.props.children}
        <CpDropdown
          triggerIsBlock
          allowContentClicks
          contentWidth={contentWidth}
          renderTrigger={({ toggle }) => (
            <input
              type="text"
              placeholder={placeholder}
              value={this.getDescription()}
              onChange={noop}
              onInput={() => {}}
              onFocus={toggle}
              className={`${inputClass || ''} cps-form-control`}
              autoComplete="off"
              disabled={disabled}
              id={id}
            />
          )}
          renderContent={({ close }) => (
            <div className={`${styles.remindersDropdown}`}>
              <div className="cp-pb-16 cp-subheader-sm cp-wt-bold">Client Reminder</div>
              <div className={styles.reminderFormGroup}>
                <label className={`cp-body-sm cp-pb-8`} htmlFor="selectReminders">
                  Every
                </label>
                <div id="selectReminders">
                  <CpSelectSingle
                    placeholder="Select weekdays, Mondays, or Tues..."
                    transformData={interval => ({
                      id: interval.key,
                      name: interval.value,
                    })}
                    data={reminderIntervalOptions}
                    onChange={this.onReminderDaysChanged}
                    value={tempReminderInterval}
                    triggerIsBlock
                    appendTo={'parent'}
                  />
                </div>
              </div>
              <div className={styles.reminderFormGroup}>
                <label className={`cp-body-sm cp-pb-8`} htmlFor="selectReminderLength">
                  For
                </label>
                <div id="selectReminderLength">
                  <CpSelectSingle
                    placeholder="Select 15 days, 30 days, or 45 days..."
                    transformData={interval => ({
                      id: interval.key,
                      name: interval.value,
                    })}
                    data={expirationIntervalOptions}
                    onChange={this.onReminderPeriodChanged}
                    value={tempExpirationIntervalDays}
                    contentWidth={'block'}
                    appendTo={'parent'}
                    triggerIsBlock
                  />
                </div>
              </div>
              <div className="cp-pb-24">or until request is completed.</div>
              <div>
                <CpButton
                  btnType="primary"
                  onClick={() => {
                    this.onSave();
                    close();
                  }}
                  disabled={!(tempExpirationIntervalDays && tempReminderInterval)}
                >
                  Add
                </CpButton>
              </div>
            </div>
          )}
        />
      </div>
    );
  }

  getDescription = () => {
    const { description, reminders, longDescription } = this.props;

    if (description) {
      return description;
    } else {
      return getHumanReminderForTool(reminders, longDescription);
    }
  };

  onSave = () => {
    const { tempReminderInterval, tempExpirationIntervalDays, returnDaysAsNumber } = this.state;
    const { setReminder } = this.props;
    if (this.isValid()) {
      setReminder(
        tempReminderInterval.key,
        returnDaysAsNumber ? tempExpirationIntervalDays.key : `${tempExpirationIntervalDays.key}`,
        reminderIntervalsMap[tempReminderInterval.key]
      );
      this.setState({ displayPicker: false });
    }
  };

  onCancel = () => {
    const { interval, expiration_interval_days } = this.props.reminders;
    const originalReminder = interval ? interval : '';
    const originalExpiration = +expiration_interval_days ? `${+expiration_interval_days}` : expiration_interval_days;
    this.setState({
      displayPicker: false,
      tempReminderInterval: originalReminder,
      tempExpirationIntervalDays: originalExpiration,
    });
  };

  isValid = () => {
    return this.state.tempReminderInterval && this.state.tempExpirationIntervalDays;
  };

  onReminderDaysChanged = option => {
    this.setState({ tempReminderInterval: option });
  };

  onReminderPeriodChanged = option => {
    this.setState({ tempExpirationIntervalDays: option });
  };
}

ReminderPicker.defaultProps = {
  reminders: {},
};
