import { useEffect, useState } from 'react';
import { getClient, getTeams } from 'src/resources/utils.resource';
import { handleError } from 'src/common/error.helper';
import { useRoles } from 'src/common/hooks/use-roles.hook';

export function useBuildRoleAssignments({ showRoles = true, clientId, possibleTeamMembers }) {
  const [roleAssignments, setRoleAssignments] = useState();
  const [listOfTeams, setListOfTeams] = useState([]);
  const [fullRoleOptions, setFullRoleOptions] = useState([]);
  const { roles, isLoading: loadingRoles } = useRoles(showRoles);

  const hasGroupData = showRoles && !!roles.length;

  useEffect(() => {
    if (clientId && hasGroupData) {
      const obs = getClient(clientId, ['users', 'roles']).subscribe(({ role_assignments }) => {
        setRoleAssignments(role_assignments);
      }, handleError);
      return () => obs.unsubscribe();
    }
  }, [clientId, hasGroupData]);

  useEffect(() => {
    if (hasGroupData) {
      const obs = getTeams().subscribe(teams => {
        setListOfTeams(teams);
      }, handleError);
      return () => obs.unsubscribe();
    }
  }, [hasGroupData]);

  useEffect(() => {
    if (hasGroupData) {
      if (roleAssignments && possibleTeamMembers?.length) {
        let orderedRolesNoAssignees = [];
        let orderedRolesAssignees = [];
        roles.forEach(role => {
          let matchingAssignments = roleAssignments.find(assignment => assignment.role_id === role.id) || {};
          matchingAssignments.type = 'role';
          if (matchingAssignments.users && (!clientId || !matchingAssignments.users[0]?.id)) {
            // If we have matching assignments and there is either no clientId (went from having a client to not having a client) or the matchingAssignemtns are just an array of ids, then we meed to match those ids with team member objects
            matchingAssignments.users = matchingAssignments.users
              .map(userId => ({
                ...possibleTeamMembers.find(tm => tm.id === userId),
              }))
              .filter(tm => tm.id);
            if (!matchingAssignments.users.length) {
              matchingAssignments.users = undefined;
            }
          }
          if (matchingAssignments.teams && (!clientId || !matchingAssignments.teams[0]?.id)) {
            matchingAssignments.teams = matchingAssignments.teams
              .map(user => ({
                ...listOfTeams.find(tm => tm.id === user),
              }))
              .filter(user => user.id);
            if (!matchingAssignments.teams.length) {
              matchingAssignments.teams = undefined;
            }
          }
          matchingAssignments.subName =
            matchingAssignments.users || matchingAssignments.teams
              ? [
                  ...(matchingAssignments.users ? matchingAssignments.users.map(user => user.name) : []),
                  ...(matchingAssignments.teams ? matchingAssignments.teams.map(team => team.name) : []),
                ].join(', ')
              : clientId
                ? 'Unassigned'
                : '';
          const fullRole = { ...role, ...matchingAssignments };
          if (matchingAssignments.users || matchingAssignments.teams) {
            orderedRolesAssignees.push(fullRole);
          } else {
            orderedRolesNoAssignees.push(fullRole);
          }
        });
        setFullRoleOptions([...orderedRolesAssignees, ...orderedRolesNoAssignees]);
      } else {
        setFullRoleOptions(
          roles.map(role => ({
            ...role,
            type: 'role',
            ...(roleAssignments?.length === 0 ? { subName: 'Unassigned' } : {}),
          }))
        );
      }
    }
  }, [roles, roleAssignments, possibleTeamMembers, listOfTeams, hasGroupData]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'clientId'

  return {
    fullRoleOptions,
    loadingRoles,
  };
}
