import { INCLUDE_COMPLETED_TASKS } from './dashboard.helpers';
import { DateTime } from 'luxon';
import { clearFilters } from './header/task-filter-groups/task-filter-groups.helper';

export const dateOptions = [
  { id: 'custom', label: 'Custom' },
  { id: 'blank', label: 'No date' },
  { id: 'with_date', label: 'With date' },
  { id: 'overdue', label: 'Overdue' },
  { id: 'yesterday', label: 'Yesterday' },
  { id: 'today', label: 'Today' },
  { id: 'tomorrow', label: 'Tomorrow' },
  { id: 'due_this_week', label: 'This week' },
  { id: 'due_this_month', label: 'This month' },
];

export const fullOptions = [
  'overdue',
  'due_last_month',
  'due_last_week',
  'yesterday',
  'today',
  'tomorrow',
  'due_this_week',
  'due_next_week',
  'due_this_month',
  'due_next_month',
  'due_later',
];

export const dateOptionsForCreatedAt = [
  { id: 'custom', label: 'Custom' },
  { id: 'due_last_month', label: 'Last month' },
  { id: 'due_last_week', label: 'Last week' },
  { id: 'yesterday', label: 'Yesterday' },
  { id: 'today', label: 'Today' },
  { id: 'due_this_week', label: 'This week' },
  { id: 'due_this_month', label: 'This month' },
];

// The second parameter is only for testing purposes
export function calculateDateInterval(filterType, date) {
  let interval = [];
  let now = date ? date : DateTime.local();
  let today = now.toISODate();
  let yesterday = now.minus({ days: 1 });
  let weekFromToday = now.plus({ week: 1 });
  let twoWeeksFromToday = now.plus({ week: 2 });
  let aWeekAgo = now.minus({ week: 1 });
  switch (filterType) {
    case 'overdue':
      interval = { end_date: yesterday.toISODate() };
      break;
    case 'due_last_month':
      const aMonthAgo = now.minus({ month: 1 });
      interval = {
        start_date: aMonthAgo.startOf('month').toISODate(),
        end_date: aMonthAgo.endOf('month').toISODate(),
      };
      break;
    case 'due_last_week':
      interval = {
        start_date: aWeekAgo.startOf('week').minus({ day: 1 }).toISODate(), // for luxon the week starts on Monday and ours starts on Sunday
        end_date: aWeekAgo.endOf('week').minus({ day: 1 }).toISODate(), // for luxon the week starts on Monday and ours starts on Sunday
      };
      break;
    case 'yesterday':
      interval = { date: yesterday.toISODate() };
      break;
    case 'today':
    case 'custom':
      interval = { date: today };
      break;
    case 'tomorrow':
      interval = { date: now.plus({ day: 1 }).toISODate() };
      break;
    case 'due_this_week':
      interval = {
        start_date: now.startOf('week').minus({ day: 1 }).toISODate(), // for luxon the week starts on Monday and ours starts on Sunday
        end_date: now.endOf('week').minus({ day: 1 }).toISODate(), // for luxon the week starts on Monday and ours starts on Sunday
      };
      break;
    case 'due_next_week':
      interval = {
        start_date: weekFromToday.startOf('week').minus({ day: 1 }).toISODate(), // for luxon the week starts on Monday and ours starts on Sunday
        end_date: weekFromToday.endOf('week').minus({ day: 1 }).toISODate(), // for luxon the week starts on Monday and ours starts on Sunday
      };
      break;
    case 'due_this_month':
      interval = {
        start_date: now.startOf('month').toISODate(),
        end_date: now.endOf('month').toISODate(),
      };
      break;
    case 'due_next_month':
      const nextMonth = now.plus({ month: 1 });
      interval = {
        start_date: nextMonth.startOf('month').toISODate(),
        end_date: nextMonth.endOf('month').toISODate(),
      };
      break;
    case 'due_later':
      const startOfWeekTwoWeeksAhead = twoWeeksFromToday.startOf('week').minus({ day: 1 }); // for luxon the week starts on Monday and ours starts on Sunday
      interval = {
        start_date: startOfWeekTwoWeeksAhead.toISODate(),
        end_date: startOfWeekTwoWeeksAhead.plus({ month: 3 }).toISODate(),
      };
      break;
    case 'blank':
    case 'with_date':
      interval = {
        date: undefined,
      };
      break;
    default:
      interval = { date: today };
      break;
  }
  return interval;
}

export function getFilterObj(filterType, userId, preferences) {
  // This builds the object we send to the backend so they can filter the tasks
  let arr = [...preferences.visibleColumns];
  !arr.includes('task.relationships.assigned_to') && arr.unshift('task.relationships.assigned_to');
  !arr.includes('task.dates.DUEDATE.calculated_date') && arr.unshift(`task.dates.DUEDATE.calculated_date`);

  let filterObj = {
    visibleColumns: arr,
    columnSettings: {
      ...clearFilters([...arr, 'task.name', 'task.status_name']),
      'task.relationships.assigned_to': {
        order: null,
        filter_params: [userId],
      },
      'date.calculated_date': [
        {
          order: null,
          filter_params: [calculateDateInterval(filterType)],
          date_group: filterType,
          date_type_id: 'DUEDATE',
        },
      ],
    },
    [INCLUDE_COMPLETED_TASKS]: !!preferences[INCLUDE_COMPLETED_TASKS],
    show_subtasks: preferences.show_subtasks,
    filter_group_id: filterType,
  };
  return filterObj;
}
