import React from 'react';
import PropTypes from 'prop-types';

import { Observable, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { createRoot } from 'react-dom/client';
import Disposable from 'react-disposable-decorator';

import { getTeammembers, getClientTeammembers } from 'src/resources/utils.resource.js';
import { getStatuses } from 'src/resources/statuses.resource.js';
import { getTask } from 'src/resources/tasks.resource';
import CreateEditEsignRequest from '../create-edit-esign-request/create-edit-esign-request.component.js';
import { emptyTask } from './create-edit-task.helpers.js';
import { handleError } from 'src/common/error.helper';
import { UserTenantProps } from 'cp-client-auth!sofe';

@Disposable
@UserTenantProps()
export default class CreateEditTaskWrapper extends React.Component {
  static propTypes = {
    task: PropTypes.object,
    taskUpdated: PropTypes.func,
    close: PropTypes.func,
    statusList: PropTypes.array,
    initialClientId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      statuses: props.statusList || [],
      teammembers: [],
      loadingTeammembers: false,
      bulkTaskClientListId: null,
    };
    this.getStatusTypes = this.getStatusTypes.bind(this);
  }

  componentDidMount() {
    if (!this.props.statusList?.length) this.getStatusTypes();
    this.getTeammembers(this.props.task && this.props.task.client_id);
    if (this.props.task) {
      if (this.props.task.parent_task_id) {
        this.getTask(this.props.task.parent_task_id);
      }
    }
  }

  render() {
    const { taskUpdated, close, loggedInUser } = this.props;
    const { teammembers, statuses } = this.state;

    let { task } = this.props;
    if (!task) {
      task = emptyTask;
    }

    const clientId = this.props.initialClientId || task.client_id;
    return (
      <CreateEditEsignRequest
        task={task}
        user={loggedInUser}
        statuses={statuses}
        clientId={clientId}
        updateAsyncData={this.updateState}
        teammembers={teammembers}
        taskUpdated={taskUpdated}
        close={close}
      />
    );
  }

  getStatusTypes() {
    this.props.cancelWhenUnmounted(
      getStatuses('generic').subscribe(statuses => {
        this.setState({ statuses });
      }, handleError),
    );
  }
  getTeammembers = clientId => {
    this.setState({
      loadingTeammembers: true,
    });
    const getTeam = clientId ? getClientTeammembers(clientId, ['tasks_esign']) : getTeammembers();
    this.props.cancelWhenUnmounted(
      getTeam.subscribe(teammembers => {
        this.setState({
          teammembers,
          loadingTeammembers: false,
        });
      }, handleError),
    );
  };
  getTask = id => {
    this.props.cancelWhenUnmounted(
      getTask(id).subscribe(task => {
        this.setState({ parentTask: task });
      }, handleError),
    );
  };

  updateState = (field, data) => {
    this.setState({
      [field]: data,
    });
  };
}

CreateEditTaskWrapper.defaultProps = {
  statusList: [],
};

export function createEditTaskModal(task, clientIds = null, statusList = []) {
  const taskObj = task instanceof Event ? null : task;
  let domEl;
  const clientId = Array.isArray(clientIds) ? null : clientIds;

  //task can either be the whole task object, or a taskId (in that case we fetch the task)
  const taskObservable = typeof taskObj === 'string' ? getTask(taskObj) : of(taskObj);
  return taskObservable.pipe(
    switchMap(taskToEdit => {
      domEl = document.createElement('div');
      document.body.appendChild(domEl);
      const root = createRoot(domEl);
      return Observable.create(observer => {
        root.render(
          <CreateEditTaskWrapper
            task={taskToEdit}
            initialClientId={clientId}
            statusList={statusList}
            taskUpdated={arg => observer.next(arg)}
            close={() => observer.complete()}
            newFlow={false}
          />,
        );
      }).pipe(
        finalize(() => {
          root.unmount(domEl);
          domEl.parentNode.removeChild(domEl);
        }),
      );
    }),
  );
}
