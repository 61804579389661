import { cloneDeep, get } from 'lodash';

export function addAnalyticsObjectToService(analyticsObj, serviceObj) {
  const service = cloneDeep(serviceObj);

  service.ancestor_type = analyticsObj.ancestor_type;
  service.ancestor_id = analyticsObj.ancestor_id;

  get(service, 'reminders', []).forEach(r => {
    r.ancestor_type = analyticsObj.ancestor_type;
    r.ancestor_id = analyticsObj.ancestor_id;
  });

  get(service, 'dates', []).forEach(d => {
    d.ancestor_type = analyticsObj.ancestor_type;
    d.ancestor_id = analyticsObj.ancestor_id;
  });

  get(service, 'relationships.files', []).forEach(f => {
    f.ancestor_type = analyticsObj.ancestor_type;
    f.ancestor_id = analyticsObj.ancestor_id;
  });

  get(service, 'subtasks', []).forEach(s => {
    s.ancestor_type = analyticsObj.ancestor_type;
    s.ancestor_id = analyticsObj.ancestor_id;

    get(s, 'reminders', []).forEach(r => {
      r.ancestor_type = analyticsObj.ancestor_type;
      r.ancestor_id = analyticsObj.ancestor_id;
    });

    get(s, 'dates', []).forEach(d => {
      d.ancestor_type = analyticsObj.ancestor_type;
      d.ancestor_id = analyticsObj.ancestor_id;
    });

    get(s, 'relationships.files', []).forEach(f => {
      f.ancestor_type = analyticsObj.ancestor_type;
      f.ancestor_id = analyticsObj.ancestor_id;
    });
  });

  return service;
}
