import { DateTime } from 'luxon';
import { capitalize, find, cloneDeep } from 'lodash';
import DateTypes from 'src/constants/date-types.constants';
import { reminderIntervalsMap } from 'src/common/reminder-picker/reminder.constants';
import { statusOptions } from '../constants/client-request-status.constants.js';
import { isClientRequest } from './tasks.helper';

export const defaultDueDate = {
  date_type_id: DateTypes.DUE,
  date: null,
  date_type_name: 'Due date',
  relative: null,
};

export function getDateTypeFromField(field) {
  return field.split('.')[2]; // task.dates.${id}.calculated_date, get that id
}

export function getHumanReminderForTool(reminder, showLongDescription) {
  if (!reminder.interval) {
    return '';
  } else if (showLongDescription) {
    return `Every ${reminderIntervalsMap[reminder.interval].fullDescription} for ${
      reminder.expiration_interval_days
    } days`;
  } else {
    return reminderIntervalsMap[reminder.interval].shortDescription;
  }
}

export function getHumanDate(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
}

export function getHumanReminder(reminder) {
  let string = '';
  string += reminder.count ? 'Once ' : 'Every ';

  if (reminder.on_week_days) {
    string += `${reminder.on_week_days.map(capitalize).join(', ')} `;
  } else {
    string += `${reminder.count ? `${reminder.interval} ` : ''}${reminder.interval_unit.slice(
      0,
      reminder.interval_unit.length - 1,
    )}${reminder.interval !== 1 && reminder.count ? 's' : ''} `;
  }

  string += reminder.remind_before ? `before` : 'after';
  string += ` the ${reminder.relationships.date_type_name}`;

  return string;
}

export function humanReadableDate({ date, dateOptionsLookup = [], statuses = [], isTemplate = false, dateTypes = [] }) {
  if (date.relationships)
    //Reminder
    return getHumanReminder(date);
  //Date
  else
    return date.relative && (!date.date || isTemplate) // if there isn't a date or if it's a template display like `1 day after...`
      ? humanReadableRelativeDate(date, dateOptionsLookup, statuses, isTemplate, dateTypes)
      : getHumanDate(date.date);
}

function humanReadableRelativeDate(date, dateOptionsLookup, statuses, isTemplate = false, dateTypes = []) {
  const count = Math.abs(date.relative.count),
    countUnit = sParens(date.relative.count_unit),
    beforeOrAfter = date.relative.count < 0 ? 'before' : 'after',
    dateText = getDateText(date.relative, dateOptionsLookup, statuses, isTemplate, dateTypes);
  return `${count} ${countUnit} ${beforeOrAfter} ${dateText}`;
}

function getDateText(relative, dateOptionsLookup, statuses, isTemplate = false, dateTypes = []) {
  //Find the related task (so that we have the task name)
  const task = find(dateOptionsLookup, t => t.taskId === relative.base_task_id) || {};
  if (relative.trigger_type === 'status') {
    //Status Triggers, find the related status (so we can display the status name)
    const status = find(statuses, s => s.id === relative.trigger_id || s.key === relative.trigger_id) || {};
    return ` ${task.taskName} is set to ${status.name || status.value}`;
  } else {
    //Relative date
    if (relative.base_type_id === 'CREATED') {
      const dueDate = getDateFromArray(task.dates, 'DUEDATE');
      return dueDate && relative.base_task_id[0] !== '_' && !isTemplate
        ? DateTime.fromISO(dueDate.date).toLocaleString(DateTime.DATE_MED)
        : 'task is created';
    } else {
      const typeName = relative.base_type_name || find(dateTypes, t => t.id === relative.base_type_id)?.name;
      const isOfParent = task.taskName ? ` of ${task.taskName}` : '';
      return `${typeName}${isOfParent}`;
    }
  }
}

function setDateBaseTypeName(date, dateTypes) {
  if (!date.relative || date.relative.trigger_type === 'status' || date.relative.base_type_name) return date;
  const base_type_name =
    date.relative.base_type_id === 'CREATED'
      ? 'Date task is created'
      : find(dateTypes, t => t.id === date.relative.base_type_id).name;
  return { ...date, relative: { ...date.relative, base_type_name } };
}

export function setReminderBaseTypeName(reminder, dateTypes) {
  if (!reminder.relationships.date_type_name) {
    const date_type_name = find(dateTypes, t => t.id === reminder.relationships.date_type_id)?.name || '';
    return { ...reminder, relationships: { ...reminder.relationships, date_type_name } };
  }
  return reminder;
}

export function setBaseTypeNames(task, dateTypes) {
  const temp = cloneDeep(task);
  temp.reminders = task.reminders?.map(r => setReminderBaseTypeName(r, dateTypes));
  temp.dates = task.dates?.map(d => setDateBaseTypeName(d, dateTypes));
  if (temp.subtasks) temp.subtasks = task.subtasks?.map(s => setBaseTypeNames(s, dateTypes));

  return temp;
}

//Creates an array of tasks, each contain an array of dates that are selectable
export function getRelDateOptions(rootTask, statuses, isTemplate = false) {
  const task = cloneDeep(rootTask);
  //Add the subtasks with their lists of dates only if they have dates
  let subtaskCount = 0;
  let crCount = 0;
  let options = task.subtasks?.map(s => {
    isClientRequest(s) ? crCount++ : subtaskCount++;
    let subtaskObj = {
      taskId: s.id,
      dates: s.dates || [],
      task_type: isClientRequest(s) ? 'simple' : 'generic',
      status_id: s.status_id,
    };
    if (!subtaskObj.dates.length && s.due_date) {
      subtaskObj.dates = [{ date: s.due_date, date_type_id: 'DUEDATE', date_type_name: 'Due date' }];
    }
    if (s.title?.trim() || s.name?.trim()) subtaskObj.taskName = s.title?.trim() || s.name?.trim();
    else if (isClientRequest(s)) subtaskObj.taskName = `Client Request ${crCount}`;
    else subtaskObj.taskName = `Subtask ${subtaskCount}`;
    return subtaskObj;
  });

  if (!options) return [];

  //Add the root task to the front of the list only if the root task has dates
  options.unshift({ taskName: task.name?.trim() || 'Parent task', taskId: task.id, dates: task.dates });
  if (isTemplate) {
    //Add applied date to all tasks
    const appliedDate = { date_type_name: 'Date task is created', date_type_id: 'CREATED' };
    options.forEach(t => t.dates.unshift(appliedDate));
  }
  // if it is of type simple (Client Request), use CR status options
  const dateOptions = options.map(option => {
    const CRstatusOptions =
      option.task_type === 'simple' && option.status_id === 'DRAFT'
        ? statusOptions
        : // if a CR is already sent, it can't be set back to DRAFT so it makes no sense to set a date relative to its moving back to DRAFT status
          statusOptions.filter(so => so.id !== 'DRAFT');
    const status = option.task_type === 'simple' ? CRstatusOptions : statuses;
    option.statusOptions = status.filter(s => !s.archived).map(s => ({ key: s.id, value: s.name }));
    return option;
  });
  return dateOptions;
}

export function relDateIsValid(relDate) {
  return (
    !!relDate?.base_task_id && (relDate?.trigger_type === 'status' ? !!relDate?.trigger_id : !!relDate?.base_type_id)
  );
}

export function sParens(value) {
  return `${value?.slice(0, value.length - 1)}(s)`;
}

export function getDateFromArray(dates, type) {
  let typeId = type;
  if (type.includes('calculated_date')) {
    typeId = getDateTypeFromField(type);
  }
  return dates?.find(date => date.date_type_id === typeId);
}
