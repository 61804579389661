import React, { useState, useEffect } from 'react';
import Color from 'color';
import { CpArea, CpTooltip, CpDropdown } from 'canopy-styleguide!sofe';
import { clientRequestStatuses as clientRequestStatusesImported } from 'src/constants/client-request-status.constants';
import { getStatusDotStyles } from 'src/common/status-input.helper';

import styles from './status-dropdown.styles.css';

type ClientRequestStatusKey = keyof typeof clientRequestStatusesImported;

type Props = {
  selectedStatus: ClientRequestStatusKey;
  onSelect: (option: any) => void;
  style?: object;
  width?: number;
  disabled: boolean;
};

export default function ClientRequestStatusDropdown(props: Props) {
  const clientRequestStatuses = clientRequestStatusesImported;
  const [status, setStatus] = useState(clientRequestStatuses.WITH_CLIENT);

  useEffect(() => {
    const effectStatus = clientRequestStatuses[props.selectedStatus]
      ? clientRequestStatuses[props.selectedStatus]
      : null;
    if (effectStatus) {
      setStatus(effectStatus);
    }
  }, [props.selectedStatus]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-td: has missing dependencies: 'clientRequestStatuses'

  const selectStatus = (option: any) => {
    setStatus(option);
    props.onSelect(option);
  };

  function lightenColor(color: any) {
    const newColor = darkenLoop(Color(color).lighten(0.8));
    return newColor.hex();
  }

  function darkenLoop(color: any) {
    if (color.lightness() > 95) return darkenLoop(color.darken(0.1));
    else return color;
  }

  const getStatusOptions = (statusOption: any) => {
    if (statusOption.value === clientRequestStatuses.NOSTATUS.value) return;
    if (statusOption.value === clientRequestStatuses.DRAFT.value) return;
    return (
      <div
        key={statusOption.value}
        onClick={() => selectStatus(statusOption)}
        data-test-class={`status-${statusOption.value}`}
        className={`${styles.status}`}
        style={statusOption.value === props.selectedStatus ? { background: lightenColor(statusOption.color) } : {}}
      >
        <div className={`${styles.statusInfo}`}>
          <CpTooltip text={statusOption.label} delay={500}>
            <div style={{ background: statusOption.color }} className={`${styles.statusDot}`} />
          </CpTooltip>
          <div className="cp-ellipsis status-name">{statusOption.label}</div>
        </div>
      </div>
    );
  };

  const disabledStatus = (useDisabledStyles = false) => {
    return (
      <div className={styles.nonClick}>
        <div className={styles.selectStatus}>
          <span className={styles.statusDot} style={getStatusDotStyles(status.color, false, useDisabledStyles)}></span>
          <div className={`cp-ellipsis ${useDisabledStyles ? 'cp-color-app-disabled-text' : ''}`}>{status.label}</div>
        </div>
      </div>
    );
  };

  if (
    props.selectedStatus === clientRequestStatuses.NOSTATUS.value ||
    props.selectedStatus === clientRequestStatuses.DRAFT.value ||
    props.disabled
  ) {
    return disabledStatus(props.disabled);
  }

  return (
    <CpDropdown
      renderTrigger={({ toggle }: { toggle: () => void }) => (
        <CpArea style={{ borderRadius: '8px' }}>
          <CpTooltip text={status.label}>
            <div className={styles.selectStatus} onClick={toggle}>
              <span className={styles.statusDot} style={getStatusDotStyles(status.color)}></span>
              <div className="cp-ellipsis">{status.label}</div>
            </div>
          </CpTooltip>
        </CpArea>
      )}
      renderContent={() => (
        <div className={`cps-card__height-2 ${styles.statusDropdown}`} style={{ width: 250, ...props.style }}>
          <div className={`${styles.scrollBox}`}>
            {Object.keys(clientRequestStatuses).map(key =>
              getStatusOptions(clientRequestStatuses[key as keyof typeof clientRequestStatuses])
            )}
          </div>
        </div>
      )}
    />
  );
}
