import { get, cloneDeep } from 'lodash';
import DateType from '../../constants/date-types.constants';
import type { Task, Service, TaskDate } from 'src/service-workspace/service-workspace.types';
import { getDateFromArray } from 'src/common/date.helpers';

export function getDueDateFromTask(task?: Service | Task, includeRelative?: boolean): TaskDate | undefined {
  if (task === undefined) {
    return;
  }
  const taskDateObj = getDateFromArray(task.dates, DateType.DUE);
  if (taskDateObj && (taskDateObj.calculated_date || taskDateObj.date || (includeRelative && taskDateObj.relative))) {
    return taskDateObj;
  } else {
    return;
  }
}

export function getAssignedTextFromTask(task: Service | Task): string {
  const assignedToArray = get(task, 'relationships.assigned_to', []);
  if (assignedToArray.length === 0) {
    return 'Unassigned';
  } else {
    const assigneeText = getAssigneeFromTask(task);
    return `Assigned to ${assigneeText}`;
  }
}

export function getAssigneeFromTask(task: Service | Task): string {
  const assignedToArray = get(task, 'relationships.assigned_to', []);
  if (assignedToArray.length === 0) {
    return 'Unassigned';
  } else {
    return assignedToArray.reduce((acc, item, index) => {
      return `${acc}${index === 0 ? '' : ' '}${item.name}${index + 1 !== assignedToArray.length ? ',' : ''}`;
    }, '');
  }
}

export const findDateIndex = (type: string, dates: any[]) => {
  return dates.findIndex(date => date.date_type_id === type);
};

export function patchSubtask({
  task,
  prevStatus,
  onFormCompleteProp,
}: {
  task: Task;
  prevStatus: string;
  onFormCompleteProp: (data: any) => void;
}) {
  const { id, name, description, status_id, priority, budgeted_hours, relationships, dates } = task;
  if (onFormCompleteProp) {
    return onFormCompleteProp({
      // update subtask
      taskIds: [id],
      newValues: {
        relationships: { ...relationships, assigned_to: relationships.assigned_to },
        name: name.trim(),
        dates: dates,
        description,
        status_id,
        priority,
        budgeted_hours,
      },
      statusChanged: prevStatus !== status_id,
    });
  }
}

type Assignee = {
  id: string;
  name: string;
  type: string;
  label?: string;
  rolesList?: string[];
  nameWithRole?: string;
  users?: { id: string; name: string }[];
  teams?: {
    id: string;
    name: string;
    users: { id: string; name: string }[];
  }[];
};

export function getAssigneesFromRoles(assigneeList: Assignee[], showUnassignedRoles = true) {
  const assignees: Assignee[] = [];
  const copiedListOfAssignees = cloneDeep(assigneeList) || [];
  const setNameWithLabel = (role: any, user: any) => {
    const indexOfUser = assignees.findIndex(obj => obj.id === user.id);
    if (indexOfUser < 0) {
      assignees.push({ ...user, nameWithRole: `${role.label}`, rolesList: [role.id] });
    } else if (!assignees[indexOfUser].rolesList?.includes(role.id)) {
      const nameWithRole = assignees[indexOfUser].nameWithRole;
      assignees[indexOfUser].rolesList = [...(assignees[indexOfUser].rolesList || []), role.id];
      assignees[indexOfUser].nameWithRole = nameWithRole ? `${nameWithRole}, ${role.label}` : `${role.label}`;
    }
  };
  copiedListOfAssignees.forEach(assignee => {
    if (assignee.type === 'role') {
      if (assignee.teams) {
        assignee.teams.forEach(team => {
          team.users.forEach(user => {
            setNameWithLabel(assignee, user);
          });
        });
      }
      if (assignee.users) {
        assignee.users.forEach(user => {
          setNameWithLabel(assignee, user);
        });
      }
      if (!assignee.teams?.length && !assignee.users?.length && showUnassignedRoles) {
        assignees.push({
          id: assignee.id,
          type: 'role',
          nameWithRole: assignee.name || assignee.label,
          name: (assignee.name || assignee.label)?.split(' ')?.[0] || '', // Just the first initial of the role will show in the name chip
        });
      }
    } else {
      assignees.push(assignee);
    }
  });
  return assignees.map(assignee => ({
    ...assignee,
    ...(assignee.nameWithRole && assignee.type !== 'role'
      ? { nameWithRole: `${assignee.nameWithRole} - ${assignee.name}` }
      : {}),
  }));
}
