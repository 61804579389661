import { track } from 'cp-analytics';
import { pluck } from 'rxjs/operators';
// sofe
import { fetchAsObservable } from 'fetcher!sofe';

import { Service, Task } from '../service-workspace.types';
import { clearRelativeDates } from 'src/common/create-edit-modal/create-edit-modal.helpers';

export function addFileRelationToTask(task: Service, files: Array<{ id: string }>, type: string) {
  if (!task) {
    throw new Error(`cannot add a relationship to a task without the task and its id`);
  }

  if (!files) {
    throw new Error(`cannot add files to a task without the files`);
  }

  let relationships = {};

  if (type === 'document_templates') {
    relationships = {
      document_templates: files,
    };
  } else if (type === 'files') {
    relationships = {
      files,
    };
  } else if (type === 'forms') {
    relationships = {
      forms: files,
    };
  }

  return fetchAsObservable(`api/tasks/${task.id}`, {
    method: 'PATCH',
    body: {
      tasks: {
        relationships,
      },
    },
  });
}

export function buildSubtaskPostObject(subtask: Task, parentTaskId: string) {
  delete subtask.client_id;
  delete subtask.resolution_case_id;
  delete subtask.recurrence;
  delete subtask.subtasks;
  const serviceCopy = clearRelativeDates(subtask);
  return createSubtask(serviceCopy, parentTaskId).toPromise();
}

export function createSubtask(body: unknown, parentTaskId: string) {
  return fetchAsObservable(`api/tasks/${parentTaskId}/subtasks`, {
    method: 'POST',
    body: { tasks: body },
  }).pipe(pluck('task'));
}

export function addNoteToTask(task: Service, notes: Array<{ id: string }>) {
  if (!task) {
    throw new Error(`cannot add a relationship to a task without the task and its id`);
  }

  if (!notes) {
    throw new Error(`cannot add notes to a task without the notes`);
  }

  return fetchAsObservable(`api/tasks/${task.id}`, {
    method: 'PATCH',
    body: {
      tasks: {
        relationships: {
          notes,
        },
      },
    },
  });
}

export function getClientById(clientId: string) {
  if (!clientId) {
    throw new Error('Cannot fetch a client by id without an id');
  }
  return fetchAsObservable(`api/clients/${clientId}?include=users`).pipe(pluck('clients'));
}

export function bulkPatchStatus(taskIds: Array<string>, status_id: string) {
  const tasksAsString = taskIds.join(',');

  return fetchAsObservable(`api/tasks/${tasksAsString}`, {
    method: 'PATCH',
    body: {
      tasks: {
        status_id,
      },
    },
  }).pipe(pluck('tasks'));
}

export function patchTaskStatus(taskId: string, status_id: string) {
  return fetchAsObservable(`api/tasks/${taskId}`, {
    method: 'PATCH',
    body: {
      tasks: {
        status_id,
      },
    },
  }).pipe(pluck('tasks'));
}

export const uploadDocument = (document: any, purpose: string, clientId: string) => {
  const file = new FormData();
  file.append('file', document);
  file.append('name', document.name);
  file.append('type', 'notice');
  file.append('purpose', purpose);
  file.append('client_id', clientId);

  return fetchAsObservable(`/documents`, {
    method: 'POST',
    body: file,
  }).pipe(pluck('document_id'));
};

export const getAdminForms = (version: string) => {
  return fetchAsObservable(`/wg/versions/${version}/admin`).pipe(pluck('groups'));
};

export function notifyAnalytics(
  eventName: string, // can be unique
  dataObject: Record<string, unknown>,
  productName: string, // needs to be whitelisted
  featureName: string, // needs to be whitelisted
  user: any,
) {
  if (!user) {
    return;
  }
  const user_id = user.id;
  const tenant_id = user.tenant_id;
  const permissions = user.permissions;
  const dataObj = {
    user_id,
    tenant_id,
    permissions,
    ...dataObject,
  };

  track(productName, featureName, eventName, dataObj);
}
