import React, { useState } from 'react';
import { CpModal, CpRadio, CpButton, CpWell } from 'canopy-styleguide!sofe';
import styles from './complete-subtasks-modal.styles.css';
import { notifyAnalytics } from 'src/service-workspace/common/common.resource';
import { useWithUserAndTenant } from 'cp-client-auth!sofe';

export function CompleteSubtasksModal({ onSubmit, isNotice }) {
  const [shouldCompleteSubtasks, setShouldCompleteSubtasks] = useState(true);
  const tasksStr = isNotice ? 'notice' : 'task';
  const [user] = useWithUserAndTenant()

  function handleCancel() {
    notifyAnalytics(
      'task_completion_modal_closed',
      {
        completionSelection: 'exit'
      },
      'practice_management',
      'tasks',
      user
    );
    onSubmit()
  }

  function handleSubmit() {
    const completionSelection = shouldCompleteSubtasks ? 'all_subtasks' : 'only_parent_task';
    notifyAnalytics(
      'task_completion_modal_closed',
      {
        completionSelection
      },
      'practice_management',
      'tasks',
      user
    );
    onSubmit(completionSelection);
  }

  return (
    <>
      <CpModal.Header title={`Complete ${tasksStr}`} onClose={handleCancel} />
      <CpModal.Body>
        <span>You are completing a {tasksStr} that contains active subtasks. Select how to proceed:</span>
        <CpRadio onChange={setShouldCompleteSubtasks} value={shouldCompleteSubtasks} name="complete-subtasks">
          <CpWell
            className={`cp-mv-16 cp-p-16 ${shouldCompleteSubtasks ? styles.active : ''} ${styles.clickable}`}
            onClick={() => setShouldCompleteSubtasks(true)}
          >
            <CpRadio.Item id={true}>
              <p className="cp-m-0">Complete parent {tasksStr} and active subtasks</p>
            </CpRadio.Item>
            <div className="cp-pt-8 cp-pl-24">
              <i className="cp-caption">Automations related to status change on completed subtasks will be skipped</i>
            </div>
          </CpWell>
          <CpWell
            className={`cp-p-16 ${!shouldCompleteSubtasks ? styles.active : ''} ${styles.clickable}`}
            onClick={() => setShouldCompleteSubtasks(false)}
          >
            <CpRadio.Item id={false}>
              <p className="cp-m-0">Complete parent {tasksStr} only</p>
            </CpRadio.Item>
          </CpWell>
        </CpRadio>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          className="cp-mr-8"
          onClick={handleSubmit}
        >
          Continue
        </CpButton>
        <CpButton onClick={handleCancel} btnType="flat">
          Cancel
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
