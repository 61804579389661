import { uniqBy, set, get } from 'lodash';
import { successToast } from 'toast-service!sofe';
import { archiveTasks, unarchiveTasks } from 'src/resources/tasks.resource';
import { toolPaths } from 'src/constants/tools.constants';
import { isClientRequest } from 'src/common/tasks.helper';
import { handleError } from 'src/common/error.helper';

export const unArchiveParentTask = ({ taskId, taskStr, onUndo }) => {
  //Do not wrap this with cancelWhenUnmounted. It is possible to be called after unmounting this component.
  //Also don't setState inside the subscription callback for the same reason
  unarchiveTasks([taskId]).subscribe(() => {
    successToast(`Your ${taskStr} has been unarchived`);
    onUndo?.();
  }, handleError);
};

export const archiveTask = ({ service, taskStr, onSuccess, onUndo }) => {
  archiveTasks([service.id])
    .toPromise()
    .then(() => {
      successToast({
        message: `Your ${taskStr} has been archived`,
        actionText: 'Undo',
        actionCallback: () => unArchiveParentTask({ taskId: service.id, taskStr, onUndo }),
      });
      onSuccess?.();
    }, handleError);
};

export const getAllFiles = service => {
  return [
    ...(service.relationships?.files || []),
    ...(service.relationships?.document_templates || []),
    ...(service.subtasks?.map(subtask => [
      ...(subtask.relationships?.files?.map(file => ({ ...file, subtask })) || []),
      ...(subtask.relationships?.document_templates?.map(document => ({
        ...document,
        subtask,
      })) || []),
    ]) || []),
  ].flat();
};

export const getAllNotes = service => {
  return [
    ...(service.relationships?.notes || []),
    ...(service.subtasks?.map(subtask => [
      ...(subtask.relationships?.notes?.map(note => ({ ...note, subtask })) || []),
    ]) || []),
  ].flat();
};

export const getAllForms = service => {
  return uniqBy(
    [
      ...(service.relationships?.forms || []),
      ...(service.subtasks?.map(subtask => [
        ...(subtask.relationships?.forms?.map(form => ({ ...form, subtask })) || []),
      ]) || []),
    ].flat(),
    'id',
  );
};

export const removeArchivedNotes = service => {
  const path = toolPaths.NOTES;
  const values = service.relationships?.notes?.filter(note => note.created_at); // notes that are archived still exist in relationship and are returned with only ID
  set(service, path, values);
  const cleanedSubtasks = service.subtasks?.map(subtask => {
    if (get(subtask, 'relationships.notes')) {
      const subtaskNoteValues = subtask.relationships?.notes?.filter(note => note.created_at);
      set(subtask, toolPaths.NOTES, subtaskNoteValues);
    }
    return subtask;
  });
  set(service, 'subtasks', cleanedSubtasks);
  return service;
};

export const removeDeletedFiles = service => {
  const clonedService = { ...service };
  clonedService.relationships.files = service.relationships.files.filter(file => !file.delete && !file.deleted_at);
  clonedService.subtasks = service.subtasks?.map(subtask => {
    subtask.relationships.files = subtask.relationships.files.filter(file => !file.delete && !file.deleted_at);
    return subtask;
  });
  return clonedService;
};

const dateHasDependentsOnTask = (dateToDelete, dateTaskID, task) =>
  task.dates.some(
    date =>
      date.relative &&
      date.relative.base_type_id === dateToDelete.date_type_id &&
      date.relative.base_task_id === dateTaskID,
  ) ||
  (dateTaskID === task.id && // check reminders only if we're looking at the task that has the date for deletion
    task.reminders.some(r => r.relationships.date_type_id === dateToDelete.date_type_id));

export const dateHasDependents = (date, dateTaskID, parentTask) => {
  return (
    dateHasDependentsOnTask(date, dateTaskID, parentTask) ||
    parentTask.subtasks.some(subtask => !isClientRequest(subtask) && dateHasDependentsOnTask(date, dateTaskID, subtask))
  );
};
