import React from 'react';
import { get, isEmpty, isNumber, map, some, sortBy } from 'lodash';
import { DateTime } from 'luxon';
import { hasAccess } from 'cp-client-auth!sofe';
import { htmlToString } from 'rich-text-ui!sofe';
import { CpIcon } from 'canopy-styleguide!sofe';
import TimeOnTask from 'src/common/time-on-task.component.js';
import TotalTimeOnTask from 'src/common/total-time-on-task.component.js';
import { getDateFromArray, getDateTypeFromField } from 'src/common/date.helpers.js';
import { isTranscriptsOnly } from 'src/common/transcripts-only-util';
import { getAssigneesFromRoles } from 'src/service-workspace/common/common.helpers';
import { dateOptions, dateOptionsForCreatedAt } from './task-date-filter.constants';

// Column keys
export let cols = {
  assigned: 'task.relationships.assigned_to',
  client: 'task.client_name',
  client_owner: 'task.client_owner',
  completed_at: 'task.completed_at',
  contact: 'task.contact_name',
  created_at: 'task.created_at',
  created_by: 'task.created_by',
  description: 'task.description',
  engagement: 'task.engagement_v2_id',
  is_recurring: 'task.is_recurring',
  name: 'task.name',
  parent_name: 'task.parent_name',
  priority: 'task.priority',
  reminders: 'task.reminder_total',
  resolutionCase: 'task.resolution_case_id',
  returnType: 'task.return_type',
  roles: 'task.relationships.role_ids',
  // To access roles go to task.relationships.assigned_to and find all objects with type role
  status: 'task.status_name',
  taxYear: 'task.tax_year',
  time: 'task.time',
  totalTime: 'task.total_time',
  type: 'task.parent_task_id',
};

export function addDateTypesToCols({ user, dateTypes, fullRoleOptions }) {
  dateTypes &&
    dateTypes.map(({ id }) => {
      cols[id] = `task.dates.${id}.calculated_date`;
    });
  if (user) {
    return allColumnDefs({ user, dateTypes, fullRoleOptions });
  }
}

export function buildColumnDefs({ user, visibleColumns = [], dateTypes, fullRoleOptions }) {
  const userHasAccess = hasAccess(user);

  let allColumnDefsObj;
  if (dateTypes) {
    allColumnDefsObj = addDateTypesToCols({ user, dateTypes, fullRoleOptions });
  } else {
    allColumnDefsObj = allColumnDefs({ user, fullRoleOptions });
  }
  return visibleColumns.reduce((defs, key) => {
    let def = allColumnDefsObj[key];
    if (!def || (def.requiredPermission && !userHasAccess([def.requiredPermission])) || def.hidden) {
      return defs;
    }
    return [...defs, def];
  }, []);
}

export const colWidthXlg = 280;
export const colWidthLg = 240;
export const colWidthMed = 200;
export const colWidthSm = 160;
export const colWidthXsm = 80;

export const stickyColumnsWidth = colWidthXsm + colWidthMed + colWidthXlg;

export const defaultSortLabelValues = {
  minSortValue: 'A',
  maxSortValue: 'Z',
};

export const filterTypes = {
  none: 'none',
  searchAndSelect: 'searchAndSelect',
  multiLayerDynamic: 'multiLayerDynamic',
  date: 'date',
};

// Column defs
export const allColumnDefs = ({ user, dateTypes, fullRoleOptions }) => {
  const transcriptsOnly = isTranscriptsOnly(user);

  let defs = {
    [cols.type]: {
      label: 'Task type',
      field: cols.type,
      width: colWidthMed,
      formatter: formatType,
      editable: false,
      hidden: transcriptsOnly,
      filter: filterTypes.searchAndSelect,
    },
    [cols.client]: {
      ...defaultSortLabelValues,
      label: 'Client',
      field: cols.client,
      width: colWidthMed,
      editable: false,
      filter: filterTypes.searchAndSelect,
    },
    [cols.assigned]: {
      ...defaultSortLabelValues,
      label: 'Assignee(s)',
      field: cols.assigned,
      width: colWidthMed,
      editable: hasAccess(user)('tasks_create_edit'),
      formatter: formatAssigned,
      hidden: !hasAccess(user)('tasks_assign'),
      filter: filterTypes.searchAndSelect,
    },
    [cols.roles]: {
      ...defaultSortLabelValues,
      label: 'Role(s)',
      field: cols.roles,
      fieldOverride: cols.assigned,
      width: colWidthMed,
      editable: row => hasAccess(user)('tasks_create_edit') && !['eSign Request', 'Notice'].includes(row.task.type),
      formatter: formatRoles,
      hidden: !fullRoleOptions?.length || !hasAccess(user)('tasks_assign'),
      filter: filterTypes.searchAndSelect,
    },
    [cols.resolutionCase]: {
      ...defaultSortLabelValues,
      label: 'Resolution Case',
      field: cols.resolutionCase,
      width: colWidthXlg,
      editable: row =>
        !!row.task.client_id &&
        !row.task.parent_task_id &&
        row.task.service_type !== 'tax_return' &&
        !row.task.resolution_case_name &&
        hasAccess(user)('tasks_create_edit'),
      formatter: formatResolutionCase,
      requiredPermission: 'tasks_resolution_cases',
      filter: filterTypes.searchAndSelect,
    },
    [cols.engagement]: {
      ...defaultSortLabelValues,
      label: 'Engagement',
      field: cols.engagement,
      width: colWidthLg,
      editable: row => row.task.engagement_v2_name,
      formatter: formatEngagement,
      requiredPermission: 'engagements',
      filter: filterTypes.searchAndSelect,
    },
    [cols.description]: {
      label: 'Description',
      formatter: displayHTML,
      field: cols.description,
      width: colWidthXlg,
      editable: row =>
        // Can't edit the description if it's an esign, but can for a regular task or subtask
        // Can't edit the description if it's a client request and it is completed or, if it's sent, the user has to have the tasks_send_client_requests permission
        row.task.type !== 'eSign Request' &&
        (row.task.type !== 'Client Request' ||
          (row.task.status_id !== 'COMPLETED' &&
            (row.task.status_id === 'DRAFT' || hasAccess(user)('tasks_send_client_requests')) &&
            hasAccess(user)('tasks_create_edit'))),
      hidden: transcriptsOnly,
      filter: filterTypes.none,
    },
    [cols.reminders]: {
      label: 'Reminders',
      field: cols.reminders,
      width: colWidthSm,
      minSortValue: 'Low',
      maxSortValue: 'High',
      editable: false,
      requiredPermission: 'tasks_assign',
      hidden: !hasAccess(user)('tasks_assign'),
      filter: filterTypes.searchAndSelect,
    },
    [cols.time]: {
      label: 'Time',
      field: cols.time,
      width: colWidthSm,
      formatter: formatTime,
      requiredPermission: 'time_tracking',
      hidden: !hasAccess(user)('time_tracking'),
      filter: filterTypes.none,
    },
    [cols.totalTime]: {
      label: 'Total Time',
      field: cols.totalTime,
      width: colWidthSm,
      formatter: formatTotalTime,
      requiredPermission: 'time_tracking',
      hidden: !hasAccess(user)('time_tracking'),
      filter: filterTypes.none,
    },
    [cols.priority]: {
      label: 'Priority',
      field: cols.priority,
      width: colWidthSm,
      editable: false,
      minSortValue: 'Low',
      maxSortValue: 'High',
      filter: filterTypes.searchAndSelect,
    },
    [cols.taxYear]: {
      label: 'Tax year',
      field: cols.taxYear,
      width: colWidthSm,
      editable: false,
      minSortValue: 'Oldest',
      maxSortValue: 'Newest',
      filter: filterTypes.searchAndSelect,
    },
    [cols.returnType]: {
      label: 'Return type',
      field: cols.returnType,
      width: colWidthSm,
      editable: false,
      minSortValue: 'Low',
      maxSortValue: 'High',
      filter: filterTypes.searchAndSelect,
    },
    [cols.parent_name]: {
      ...defaultSortLabelValues,
      label: 'Parent task',
      field: cols.parent_name,
      width: colWidthMed,
      editable: false,
      filter: filterTypes.multiLayerDynamic,
    },
    [cols.completed_at]: {
      label: 'Completed date',
      field: cols.completed_at,
      editable: false,
      width: colWidthMed,
      formatter: formatCompletedDate,
      minSortValue: 'Current',
      maxSortValue: 'Future',
      hidden: true,
      filter: filterTypes.none,
    },
    [cols.is_recurring]: {
      label: 'Recurring',
      field: cols.is_recurring,
      editable: false,
      width: colWidthSm,
      formatter: formatRecurring,
      minSortValue: 'Recurring',
      maxSortValue: 'Not recurring',
      filter: filterTypes.searchAndSelect,
      filterValues: [
        { id: false, label: '(Blank)' },
        { id: true, label: 'Recurring' },
      ],
    },
    [cols.created_by]: {
      label: 'Created by',
      field: cols.created_by,
      editable: false,
      width: colWidthMed,
      filter: filterTypes.searchAndSelect,
      formatter: formatUser,
    },
    [cols.client_owner]: {
      label: 'Client owner',
      field: cols.client_owner,
      editable: false,
      width: colWidthMed,
      filter: filterTypes.searchAndSelect,
      formatter: formatUser,
    },
  };

  if (dateTypes) {
    [
      {
        name: 'Created date',
        id: 'created_at',
        editable: false,
        formatter: formatCreatedDate,
        dateOptionsArray: dateOptionsForCreatedAt,
      },
      ...dateTypes,
    ].map(({ name, id, editable = true, formatter = formatDate, dateOptionsArray = dateOptions }) => {
      defs[cols[id]] = {
        label: name,
        field: cols[id],
        editable: row =>
          hasAccess(user)('tasks_create_edit') &&
          ((!['Client Request', 'Notice', 'eSign Request'].includes(row.task.type) && editable) || id === 'DUEDATE'),
        width: colWidthMed,
        formatter: formatter,
        minSortValue: 'Current',
        maxSortValue: 'Future',
        columnType: 'dateType',
        filter: filterTypes.date,
        dateOptions: dateOptionsArray,
        isDate: true,
        dateTypeId: id,
      };
    });
  }

  return defs;
};

function formatUser(row, field) {
  return get(row, field)?.name;
}

function formatTime({ task }) {
  return task.type !== 'Client Request' && task.type !== 'eSign Request' ? <TimeOnTask task={task} /> : null;
}

function formatTotalTime({ task }) {
  return task.type !== 'Client Request' && task.type !== 'eSign Request' && !task.parent_task_id ? (
    <TotalTimeOnTask task={task} />
  ) : null;
}

function formatType(row) {
  return row.task.type;
}

export function formatAssigned(row, field, loggedInUser) {
  let assignedObj = get(row, field) || [];
  assignedObj = getAssigneesFromRoles(assignedObj, false);
  assignedObj = sortBy(assignedObj, assignee => assignee.name || assignee.label);
  return map(assignedObj, assignee =>
    assignee.id === loggedInUser.id && !assignee.name?.includes('(me)')
      ? `${assignee.name} (me)`
      : assignee.name || assignee.label,
  ).join(', ');
}

export function formatRoles(row) {
  let assigneeObj = get(row, cols.assigned);
  return assigneeObj
    .filter(assignee => assignee.type === 'role')
    .map(assignee => assignee.name || assignee.label)
    .join(', ');
}

function formatResolutionCase(row) {
  return row.task.resolution_case_name;
}

function formatEngagement(row) {
  return row.task.engagement_v2_name;
}

function displayHTML(row, field) {
  const html = get(row, field);
  return htmlToString(html);
}

export function getDate(dates, field) {
  // if this task was updated from a tickle, the date structure is different
  let dateType = getDateTypeFromField(field);
  if (Array.isArray(dates)) {
    return getDateFromArray(dates, dateType);
  } else {
    return dates[dateType];
  }
}

function formatCreatedDate(task) {
  const date = task.task.created_at;
  return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT) : null;
}

export function formatDate(row, field) {
  let dateObj = getDate(row.task.dates, field);
  let date = dateObj?.calculated_date || dateObj?.date; // if this task was updated from a tickle, the date structure is different

  let printedDate = DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);

  return !isEmpty(date) ? (
    isDateInThePast(date) && row.task.status_id !== 'COMPLETED' && field.includes('DUEDATE') ? (
      <strong>Overdue{` ${printedDate}`}</strong>
    ) : (
      printedDate
    )
  ) : null;
}

export function formatCompletedDate(row) {
  const date = row.task.completed_at;
  return date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT) : null;
}

export function formatRecurring(row) {
  const isRecurring = !!row.task.recurrence_data;
  return isRecurring ? <CpIcon name="checkmark-large" style={{ marginLeft: '-4px' }} /> : null;
}

function isDateInThePast(theDate) {
  let today = DateTime.local().startOf('day');
  let date = DateTime.fromISO(theDate).startOf('day');
  return today > date;
}

export function filtersAreApplied(columnSettings) {
  return some(columnSettings, item => {
    if (Array.isArray(item)) {
      return some(item, dateTypes => dateTypes.filter_params && dateTypes.filter_params.length);
    }
    return item.filter_params && item.filter_params.length;
  });
}

export function getCellValue(task, field, formatter, user) {
  if (!field) return null;

  let val = formatter ? formatter(task, field, user) : get(task, field);
  if (typeof val === 'string') {
    val = val.trim();
  }
  if (field === 'task.description') {
    val = val?.substring(0, 200);
  }
  return (isNumber(val) && val > 0) || !isEmpty(val) ? val : '—';
}

export const shouldHaveTooltip = columnField =>
  ['assigned_to', 'client_name', 'role_ids'].some(field => (columnField ? columnField.includes(field) : false));

export const getVisibleColumnsByType = ({ activeFilter, allColumnDefinitions, columnType }) => {
  const allVisibleFields = activeFilter?.data?.jql?.visibleColumns || [];
  return allVisibleFields.reduce((acc, field) => {
    const column = allColumnDefinitions[field];
    if (column && column.columnType === columnType) {
      acc.push(column);
    }
    return acc;
  }, []);
};

export const defaultDateColumns = [
  { label: 'Start date', id: 'task.dates.STARTDATE.calculated_date', field: 'task.dates.STARTDATE.calculated_date' },
  { label: 'Due date', id: 'task.dates.DUEDATE.calculated_date', field: 'task.dates.DUEDATE.calculated_date' },
  { label: 'Created date', id: 'task.created_at', field: 'task.created_at' },
];

export const SELECTION_TASK = 'Task';
export const SELECTION_SUBTASK = 'Subtask';
export const SELECTION_TASK_WITH_SUBS = 'Task w/subs';
