import { ClientRequest, Task } from '../service-workspace/service-workspace.types';

export const isClientRequest = (item: Task | ClientRequest): item is ClientRequest =>
  (!!item && 'request_tools' in item && Array.isArray(item.request_tools) && item.request_tools.length > 0) ||
  item?.type === 'client_request' ||
  item?.type === 'Client Request';

export const decimalNumberFilter = ({
  value,
  precision = 2,
  allowComma = false,
  allowNegative = false,
}: {
  value: string;
  precision?: number;
  allowComma?: boolean;
  allowNegative?: boolean;
}) => {
  {
    let regExpression;
    if (allowComma && allowNegative) {
      regExpression = /[^0-9.,-]+/g;
    } else if (allowComma) {
      regExpression = /[^0-9.,]+/g;
    } else if (allowNegative) {
      regExpression = /[^0-9.-]+/g;
    } else {
      regExpression = /[^0-9.]+/g;
    }
    let clean = value.replace(regExpression, '');
    const firstDecimal = clean.indexOf('.');
    if (firstDecimal >= 0) {
      //only one decimal point
      const secondDecimal = clean.indexOf('.', firstDecimal + 1);
      if (secondDecimal >= 0) {
        clean = clean.substring(0, secondDecimal);
      } else if (precision) {
        //precision
        const endIndex = Math.min(clean.length, clean.indexOf('.') + precision + 1);
        clean = clean.substring(0, endIndex);
      }
      if (allowComma) {
        const commaAfterDecimal = clean.indexOf(',', firstDecimal + 1);
        if (commaAfterDecimal >= 0) {
          clean = clean.substring(0, commaAfterDecimal);
        }
      }
    }

    if (clean === '.') return '0.';
    return clean;
  }
};

// Builds an object with the value at the given path, where path is an array of field names
// buildObject(['user', 'id'], 1) => {user: {id: 1}}
export function buildObject(path: string, value: any): Record<string, any> {
  if (path.length > 1 && path[1] === 'assigned_to' && !value) value = []; // assigned_to should be an empty array not 'null'
  if (path.length === 1) {
    return { [path[0]]: value };
  }
  return { [path[0]]: buildObject(path.slice(1), value) };
}
