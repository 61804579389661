import React, { lazy, Suspense } from 'react';
import { CpLoader } from 'canopy-styleguide!sofe';

const TimeOnTaskLazy = lazy(() =>
  SystemJS.import('billing-ui!sofe').then(billingUI => billingUI.getTimeOnTask().then(widget => ({ default: widget })))
);

export default function TotalTimeOnTask(props) {
  const { task } = props;

  return (
    <Suspense fallback={<CpLoader />}>
      <TimeOnTaskLazy task={task} displayAggregate />
    </Suspense>
  );
}
